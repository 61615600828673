.navbar-brand img {
  width: 64px;
}
.logOut button {
  width: 120px;
  padding: 10px;
  border-radius: 10px;
  font-weight: bold;
  border: 1px solid #000;
  margin: 10px 0;
}
.logOut {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
}
.logOut p,
.logOut span {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 280px;
  padding: 0rem 1rem 0 1rem;
  font-size: 0.8rem;
  color: #aaa;
}
.logOut span {
  margin-bottom: 1rem;
  color: #b30000;
}
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar-nav {
  gap: 10px;
}
