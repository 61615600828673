.login,
.login_container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.login_container {
  background-image: url("../../images/login.jpg");
  background-size: cover;
  object-fit: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.login_form {
  width: 350px;
  margin: 10px;
  padding: 2rem 1rem;
  background-color: #fff;
  box-shadow: 4px 4px 10px #333;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  flex-direction: column;
  border-radius: 16px;
}
.login_form > h3 {
  font-size: 2rem;
  color: #222;
  font-weight: bold;
}
.login_form input {
  width: 100%;
  border-radius: 16px;
  border: 2px solid #aaa;
  padding: 10px;
  outline: none;
}
.login_form button {
  padding: 10px 20px;
  border: 2px solid #aaa;
  border-radius: 16px;
  width: 120px;
  font-weight: bold;
  transition: 0.3s ease;
}
.login_form button:hover {
  background-color: #222;
  color: #fff;
  border: 2px solid #222;
}
.errorPage,
.errorPage_container {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  background-color: #222;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 999;
  pointer-events: none;
}
.error_404 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  position: relative;
}
.error_404 > h1 {
  position: absolute;
  top: -10px;
  font-size: 4rem;
  font-weight: bold;
  color: rgb(234, 2, 2);
}
.error_404 img {
  width: 250px;
}
.errorPage_container p {
  font-size: 1.2rem;
  color: #fff;
}
.errorPage_container span {
  color: #aaa;
  font-size: 1.2rem;
}
.view_content {
  width: 100%;
  display: none;
}
.loader {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid gray;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 1350px) {
  .errorPage {
    display: none;
  }
  .view_content {
    display: block;
  }
}
